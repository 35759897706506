export default defineNuxtPlugin((nuxtApp) => {
    // @ts-ignore
    const getAccessGroup = () => decryptString(nuxtApp.$cookies.get(CookieNameTypes.GROUP_KEY) || '');

    const getPermission = (permission: string) => {
        if (permission === PermissionTypes.ORDER) {
            if (getAccessGroup() === RoleTypes.SUPPLIER) {
                return true;
            }
            // @ts-ignore
            return decryptString(nuxtApp.$cookies.get(CookieNameTypes.ORDER_KEY) || '') === 'true';
        }

        if (permission === PermissionTypes.TRAINING) {
            // @ts-ignore
            return decryptString(nuxtApp.$cookies.get(CookieNameTypes.TRAINING_KEY) || '') === 'true';
        }

        return false;
    };

    const applyVnodeVisibilty = (el: HTMLElement, binding: any, vnode: any) => {
        if (binding.arg === 'not') {
            if (binding.value === getAccessGroup()) {
                vnode.el.parentNode && vnode.el.parentNode.removeChild(vnode.el);
            }

            return;
        }

        if (binding.value !== getAccessGroup()) {
            vnode.el.parentNode && vnode.el.parentNode.removeChild(vnode.el);
        }
    };

    nuxtApp.vueApp.directive('role', {
        mounted: applyVnodeVisibilty,
        updated: applyVnodeVisibilty,
    });

    nuxtApp.provide('role', getAccessGroup());
    nuxtApp.provide('hasRole', (role: string) => role === getAccessGroup());
    nuxtApp.provide('can', (permission: string) => getPermission(permission));
});
