import type {
    FetchContext,
    FetchResponse
} from 'ofetch';
import type { $Fetch } from 'nitropack';
import { getAccessWithRefreshToken, removeTokensFromCookieRedirectToLogin } from '~/composables/api/abstractApiHelperFunctions';

export const createAbstractFetch = (): $Fetch => {
    const { $cookies } = useNuxtApp();

    return $fetch.create({
        async onRequest(context: FetchContext): Promise<undefined> {
            const accessToken = $cookies.get(CookieNameTypes.TOKEN_KEY);
            const refreshToken = $cookies.get(CookieNameTypes.REFRESH_KEY);
            const group = $cookies.get(CookieNameTypes.GROUP_KEY);

            context.options.headers = {
                ...context.options.headers,
                Accept: 'application/json',
                'Accept-Language': 'de_DE',
            };

            if (accessToken && group) {
                context.options.headers = {
                    ...context.options.headers,
                    ...accessToken && { Authorization: accessToken },
                };

                if ($cookies.get(CookieNameTypes.TOKEN_KEY) && !$cookies.get(CookieNameTypes.ORDER_KEY)) {
                    removeTokensFromCookieRedirectToLogin();
                }
            } else if (refreshToken && context.request !== '/refresh-tokens' && context.request !== '/access-tokens') {
                const response = await getAccessWithRefreshToken();

                return Promise.reject(response);
            } else if (!accessToken && !refreshToken && context.request !== '/access-tokens') {
                removeTokensFromCookieRedirectToLogin();
            }

            return undefined;
        },
        async onResponseError({ response }: { response: FetchResponse<any> | FetchResponse<ResponseType> }) {
            const accessToken = $cookies.get(CookieNameTypes.TOKEN_KEY);
            const refreshToken = $cookies.get(CookieNameTypes.REFRESH_KEY);

            if (response.status === ResponseStatusTypes.UNAUTHORIZED) {
                if (response.url.indexOf('/access-tokens') >= 0 || (!refreshToken && !accessToken)) {
                    return Promise.reject(response);
                } else if (refreshToken && response.url.indexOf('/refresh-tokens') < 0) {
                    await getAccessWithRefreshToken();

                    return undefined;
                }

                removeTokensFromCookieRedirectToLogin();
            }

            return Promise.reject(response);
        }
    }) as $Fetch;
};
