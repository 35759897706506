import type { Customer } from '~/composables/types/api/searchDiscover/customer';
import getAccessToken from '~/composables/api/searchDiscover/getAccessToken';
import getCustomerData from '~/composables/api/searchDiscover/getCustomerData';
import getData from '~/composables/api/handleData';
import type { LocationQuery } from 'vue-router';

const {
    getCachedData,
    updateCachedData,
    removeCachedData,
} = useLocalStorageCache(LocalStorageNameTypes.CUSTOMER);

export default function() {
    const { $cookies } = useNuxtApp();
    const loginPending = ref(false);
    const loginFailed = ref(false);
    const isAuthenticated = ref(Boolean($cookies.get(CookieNameTypes.REFRESH_KEY)));
    const config = useRuntimeConfig();
    const userData:Ref<Customer> = ref({
        firstName: '',
        lastName: '',
        gender: null,
        email: '',
        dateOfBirth: null,
        salutation: '',
        createdAt: '',
        updatedAt: '',
    });

    const checkGroupOrderParam =
        (order: string, group: string) => group === RoleTypes.SUPPLIER ||
            group === RoleTypes.EMPLOYEE ||
            [config.public.canOrder, config.public.canNotOrder].includes(order);

    const login = async(username: string, password: string, query: LocationQuery) => {
        loginPending.value = true;

        const order = query?.order?.toString() ?? '';
        const training = query?.training?.toString() ?? '';
        const override = query?.override?.toString() ?? '';
        const UseUserLoginParams: { [key: string]: string; } = {
            username: username,
            password: password,
        };
        if (override.trim().length > 0) {
            UseUserLoginParams.override = override;
        }

        const { data } = await getAccessToken(UseUserLoginParams) as any;

        if (!data || data?.value?.error?.value || !checkGroupOrderParam(order, data?.attributes?.group || '')) {
            loginFailed.value = true;
            loginPending.value = false;

            return;
        }

        if (data?.attributes?.accessToken) {
            const newAccessTokenBearer = `Bearer ${data.attributes.accessToken}`;
            const newRefreshTokenBearer = data.attributes.refreshToken;
            const group = encryptString(data.attributes.group);
            const userCanOrder = encryptString((config.public.canOrder === order || data.attributes.group === RoleTypes.EMPLOYEE).toString());
            const userIsTraining = encryptString((config.public.isTraining === training).toString());
            // eslint-disable-next-line
            const oneWeek = 60 * 60 * 24 * 7;

            $cookies.set(CookieNameTypes.TOKEN_KEY, newAccessTokenBearer, data.attributes.expiresIn);
            $cookies.set(CookieNameTypes.REFRESH_KEY, newRefreshTokenBearer, oneWeek);
            $cookies.set(CookieNameTypes.GROUP_KEY, group, oneWeek);
            $cookies.set(CookieNameTypes.ORDER_KEY, userCanOrder, oneWeek);
            $cookies.set(CookieNameTypes.TRAINING_KEY, userIsTraining, oneWeek);
            removeCachedData();

            loginPending.value = false;
        }
    };

    const logout = () => {
        $cookies.remove(CookieNameTypes.REFRESH_KEY);
        $cookies.remove(CookieNameTypes.TOKEN_KEY);
        removeCachedData();
    };

    const getUserData = async() => {
        let data = <Customer | null>getCachedData();

        if (!data?.id) {
            const request = await getCustomerData() as any;
            const response = getData(request)?.[0];

            data = response?.attributes as Customer;
            if (data?.email) {
                data.id = response.id;
            }
            updateCachedData(data);
        }

        userData.value = data;
    };

    return {
        login,
        logout,
        loginPending,
        loginFailed,
        isAuthenticated,
        getUserData,
        userData
    };
}
