import { EnvironmentTypes } from '~/composables/types/environmentTypes';
import type { Customer } from '~/composables/types/api/searchDiscover';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const {
        getCachedData,
    } = useLocalStorageCache<Customer | undefined>(LocalStorageNameTypes.CUSTOMER);

    nuxtApp.provide('userFlow', {
        loadUserFlow(): boolean {
            window.dataLayer = window.dataLayer || [];
            const cachedData = getCachedData();
            if (config.public.releaseStage &&
                config.public.releaseStage !== EnvironmentTypes.develop &&
                config.public.releaseStage !== EnvironmentTypes.test &&
                window.dataLayer[0]?.Userflow &&
                cachedData?.id
            ) {
                window.dataLayer?.push({
                    userFlowId: cachedData.id,
                    userFlowMail: cachedData.email ?? '',
                    userFlowEnvironment: config.public.releaseStage ?? '',
                    event: 'event_user_data_available',
                });

                return true;
            }

            return false;
        },
    });
});
