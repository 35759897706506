

export default {
    model_number: 'M-Nr.',
    model_number_predecessor: 'Pred: ',
    model_number_successor: 'Suc: ',
    model_number_predecessor_title: 'predecessor model',
    model_number_successor_title: 'successor model',
    product_groups: "PGR:",
    ek_price: 'EK',
    kern_price: 'KERN',
    vk_price: 'VK',
    upe_price: 'UPE',
    upa_price: 'UPA',
    m_number: 'M-Nr.',
    from: 'from',
    color_number: 'ColorNo.',
    matrix: 'Matrix',
    single_purchase: 'Single Purchase',
    product_info: 'Product Info',
    product_infos: 'Product informations',
    volume_discount: 'Volume Discount',
    item_list: 'Item list',
    nos_tooltip: 'Never Out of Stock (NOS) items are stocked in such a way that they are available every day throughout the entire product lifecycle.',
    ps_tooltip: 'Price jumps usually occur for products with material or size variations.',
    kern_tooltip: 'KERN articles are products exclusively from industrial brands, which are supplied to core participants directly by the respective brand manufacturer at core conditions.',
    saison_tooltip: 'A SEASONAL item often experiences a highly fluctuating seasonal demand. Therefore, it can happen that a SEASONAL item is sold out before the end of the main season.',
    ms_tooltip: 'Quantity Scale: This product becomes cheaper when purchasing in larger quantities. You can find the price details in the tier information in the matrix or on the product detail page.',
    fk_tooltip: 'FOKUS KERN articles are articles with the best conditions from the most important industry and exclusive brands, which are delivered to you exclusively from the INTERSPORT warehouse.',
    variants: 'Variants',
    lot_table_header: 'Included Colors & Sizes',
    netto_tooltip: 'There are no further discounts for a NET article.',
    notes_overview: 'Notes overview',
    no_notes_available: 'No notes available',
    add_notes_text: 'Add notes to models to see them here.',
    create_note: 'Create note for',
    edit_note: 'Edit note for',
    characters: 'Characters',
    add_note: 'Add note...',
    created: 'Created',
    note_saved: "Note saved!",
    note_not_saved: "Note not saved!",
    note_deleted: "Note deleted!",
    note_saved_text: 'Note was saved successfully!',
    note_deleted_text: 'Note was deleted successfully!',
    note_not_loaded: 'Note could not be loaded!',
    saving: 'Saving note',
    note_is_saved: 'Note saved',
    last_modified: 'Last modified',
    loading_note: 'Loading note',
    delete_note: 'Delete note',
    delete_note_text: 'Do you really want to delete this note?',
    created_edited: 'Created/Edited: ',
    by: 'by',
    notes: 'Notes',
    add_to_cart: 'Add to cart',
    fedas: 'Fedas',
    add_fedas_title: 'Add member-fedas',
    search_fedas_placeholder: 'search for fedas-nr or -term',
    reset_fedas: 'reset to original-fedas',
    save_fedas: 'Save fedas',
    update_fedas_error_headline: 'Failed',
    update_fedas_error_text: 'Choice could not be saved.',
    immediately_available: 'Immediately available',
    low_inventory: 'Low inventory',
    not_available: 'Not available',
    expected_more_available_from: 'expected more available from',
    available_again: 'available again from {0}',
};
