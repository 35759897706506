export default {
    relevance: 'By Relevance',
    product_groups: 'Product groups',
    name_asc: 'Alphabetisch',
    price_asc: 'Preis aufsteigend',
    price_desc: 'Preis absteigend',
    supplier: 'Supplier',
    also_at: 'Also at',
    description: 'Description',
    sorting: 'Sorting',
    item: 'Product Models',
    color_title: 'Color: ',
    brand_title: 'Brand: ',
    tile: 'Tile',
    list: 'List',
};
