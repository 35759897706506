export default {
    checkout_page_title: 'Adressauswahl & Freigabe',
    billing_address: 'Rechnungsadresse',
    shipping_address: 'Lieferadresse',
    c_name: 'C-Name',
    gln: 'GLN',
    custom_number: 'Kundennummer',
    address: 'Adresse',
    stp_name: 'STP-Name',
    branch_office: 'Filiale',
    quantity: 'Menge',
    upe: 'Upe',
    suppliers: 'LIEFERANTEN',
    total_short: 'EK',
    pieces_short: 'Stk.',
    total_upe: 'Gesamt UPE',
    total_without_discount: 'Gesamt EK exkl. Rabatt',
    less_discount: 'Abzüglich rabatt',
    full_total: 'Gesamtsumme',
    release_shopping_cart: 'Warenkorb freigeben',
    thank_you_text: 'Vielen Dank für Ihre Bestellung',
    thank_you_secondary:
        'Ihre Bestellung wurde abgeschickt und wird schnellstmöglich vom XYZ-Team bearbeitet.',
    download_order_details: 'Bestelldetails Herunterladen',
    winter_sport_news: 'NEUIGKEITEN WINTERSPORT',
    to_collection: 'Zur Kollektion',
    winter_basics: 'WINTERSPORT BASICS',
    innovative_protectors: 'INNOVATIVE PROTEKTOREN',
    order_overview: 'Übersicht Bestellungen',
    order_overview_details: 'GR= Größe, ST= Stück, HASP= Handelsspanne',
    product: 'Produkt',
    variant: 'Variante',
    size_short: 'GR',
    piece_short: 'ST',
    trading_margin_short: 'HASP',
    supplier: 'Lieferant',
    discount: 'Rabatt',
    final_price: 'Endpreis',
    final_price_info: 'Gesamtsumme inkl. angewendeter Rabatte',
    total_short_info: 'Gesamtsumme exkl. Rabatte',
    applied_discount: 'Angewendete Rabatte:',
    supplier_discount: 'Lieferant Rabatt:',
    quantity_discount: 'Stückzahl Rabatt:',
    discount_x: 'Rabatt X:',
    total_discount: 'Gesamt Rabatt:',
    thank_you_page_title: 'Danke für ihre Bestellung',
    delivery_date: 'Liefertermin'
};
