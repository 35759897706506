 

export default {
    supplier: 'Supplier',
    deadline: 'Deadline',
    amount: 'Amount',
    ek_type_price: 'EK',
    exclusive_discounts: 'excl. Discounts',
    upe_upa: 'UPE / UPA',
    dealer_margin: 'Dealer margin',
    ek_total: 'EK Total',
    upe_total: 'UPE Total',
    discount: 'Discounted',
    total_ek: 'Total(EK)',
    taxes: 'Taxes',
    suggestions: 'Suggestions',
    supplier_selection: 'Supplier Selection',
    supplier_missing: 'You need to select at least 1 supplier',
    mbm: 'MBM',
};
