<template>
    <div :class="left">
        <template v-if="text !== ''">
            <div v-if="infoNoBackground" class="info-no-bg">{{ text }}</div>
            <div v-else class="info">
                <div>{{ text }}</div>
                <button v-if="closeable" type="button" @click="close">
                    <AtomIcon
                        class="icon"
                        name="x"
                        width="16"
                        preserveAspectRatio="xMidYMid meet"
                    />
                </button>
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
    text?: string,
    infoNoBackground?: boolean,
    closeable?: boolean,
    leftGap?: boolean,
    tooltip?: string
}>(), {
    tooltip: undefined,
    text: '',
    infoNoBackground: false,
    closeable: false,
    leftGab: false,
});

const left_gap = props.leftGap ? 'left' : '';
const left = ref(`${left_gap}`);

const emit = defineEmits<{
    (e: 'close', event: MouseEvent): void,
}>();

const close = (event: MouseEvent) => {
    emit('close', event);
};
</script>
<style lang="scss" scoped>
@mixin bestit-vsf-blue-info() {
    .info {
        padding: sp(xxs) sp(xs2);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @include helper-color-bg(bright-blue);
        @include helper-color(white);
        @include helper-border($setting-color-bright-blue, rem(0), solid);
        @include helper-border-radius(rem(18));
        @include helper-font-size(smaller);
        @include helper-font-line-height(5);
        @include helper-font-weight(medium);
    }

    .info-no-bg {
        padding: sp(xxs) sp(xs2);

        @include helper-color-bg(transparent-background);
        @include helper-color(bright-blue);
        @include helper-border($setting-color-bright-blue, rem(1), solid);
        @include helper-border-radius(rem(18));
        @include helper-font-size(smaller);
        @include helper-font-line-height(5);
        @include helper-font-weight(medium);
    }

    button {
        cursor: pointer;
        margin-left: sp(xxs);

        @include helper-color-bg(bright-blue);
        @include helper-color(white);
    }

    .icon {
        display: block;
        margin: auto;
        width: rem(16);
        height: rem(16);
    }

    .left {
        margin-left: sp(xs);
    }
}

@include bestit-vsf-blue-info();
</style>
