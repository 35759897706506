

export default {
    m_nr: 'M-Nr',
    not_available_products: 'The following products are no longer available',
    no_longer_available_products: 'The following products will soon no longer be available',
    available_products: 'Available products',
    remove_all: 'Remove All',
    delete_cart: 'Clear Cart',
    cancel_cart: 'Cancel Cart',
    change_item: 'Change Item',
    total: 'Total',
    products: 'Products',
    subtotal_upe: 'SubTotal (UPE)',
    minus_discount: 'Minus Discount',
    total_upe: 'Total (UPE)',
    back: 'Back',
    continue: 'Continue',
    total_uvp: 'Total UVP',
    total_ek: 'Total EK',
    gross_ek: 'Gross EK',
    net_ek: 'Net EK',
    pieces: 'Pc',
    upe: 'UPE',
    percent_from: '%From',
    status: 'Status',
    price_discounted: 'Discounted',
    total_price_discounted: 'Total Discounted. EK',
    order_status_expired: 'Order deadline expired',
    order_status_almost_over: 'Order deadline is almost over',
    order_possible: 'Order possible',
    confirm_delete_item_in_cart: 'Are you sure you want to delete the product?',
    message_delete_item_in_cart: 'Deleted producst cannot be restored.',
    form_edit_name_title: 'Change name',
    confirm_delete_cart: 'Are you sure you want to delete the sopping cart?',
    message_delete_cart: 'Deleted shopping carts cannot be restored.',
    shopping_carts: 'Shopping carts',
    input_name: 'New cart name...',
    create_shopping_cart: 'Create shopping cart',
    create_shopping_cart_error: 'Please enter a name for the shopping cart',
    edit_name: 'Edit name',
    cart_page_title: 'Shopping cart',
    cart_name: 'Name',
    cart_sum: 'Sum',
    general_presentation: 'General presentation',
    active: 'Active',
    cart_info: 'Cart / Information',
    create_new_cart: 'Create a new shopping cart',
    all_carts: 'All shopping carts',
    to_cart: 'To Cart',
    supplier_summary: 'Supplier Summary',
    decline: 'Decline',
    delete: 'Delete',
    cancelable: 'Cancel',
    delete_message: 'Do you want to delete your Cart?',
    cancelable_message: 'Do you want to cancel your Cart?',
    is_not_deletable_message: 'Attention, this shopping cart is mandatory or partially released and therefore cannot be deleted.',
    error_400: "The shopping cart name must contain at least 40 characters.",
    error_403: "Cart name already exists. Please enter a different name.",
    error_409: "Cart name already exists. Please enter a different name.",
    error_600_cart_create: "The shopping cart name already exists or contains more than 40 characters.",
    alternative_address_required: 'No supplier/module selected',
    alternative_address_required_text: 'At least one supplier/module must be selected',
    alternative_address_info: 'The available addresses can be added via the Intersport portal',
    alternative_address_success: 'Delivery address changed',
    alternative_address_success_text: 'The delivery address was successfully changed',
    cart_created_headline: 'Created',
    cart_created_message: 'Your Cart is ready to use.',
    deleted_headline: 'Delete',
    deleted_message: 'Your Cart has been deleted.',
    legend: 'Legend',
    status_new: 'New',
    status_edit: 'In progress',
    status_done: 'Done',
    status_headline: 'Status change',
    status_message: 'The cart status was successfully changed.',
    cart: 'Cart',
    capture: 'Capture',
    matrix: 'Matrix',
    total_carts: 'Total carts',
    total_quantities: 'Total piece',
    total_gross_ek: 'Total gross EK',
    total_net_ek: 'Total net EK',
    total_dealer_margin: '%Ab Dealer margin',
    cart_selected: 'Cart selected',
    cart_selected_text: 'The cart "{0}" was successfully selected',
    choose_alternative_delivery_address: 'Choose alternative delivery addresses',
    change_addresses_for_wee: 'Change addresses for final goods recipients.',
    open_in_new_tab: 'Open in new tab',
    open_in_same_tab: 'Open here',
    status_closed: 'Freigegeben',
    module: 'Module',
};
