export default {
    demo_page: 'PDP',
    show_info_for: 'Show information for',
    description: 'Description',
    prod_details: 'Product details',
    also_at: 'Also at',
    related_color_lots: 'Weitere Farblots',
    select_color: 'Produkt Farbe wählen',
    select_size: 'Sizes',
    lot: 'lot',
    display: 'display',
    product_package: 'Product Package',
    model_in_cart_headline: 'Model is available in the following carts',
};
