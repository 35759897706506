export default {
    mouse: 'Mouse',
    keyboard: 'Keyboard',
    branch_cluster_update_title: 'Update successful',
    branch_cluster_update_text_changed: 'Branch class distribution was successfully changed',
    branch_cluster_update_text_disabled: 'Branch class distribution has been removed',
    delivery_date_modal_title: 'Delivery Date',
    delivery_date_nos_modal_title: 'Post Order Date',
    delivery_date_modal_text: 'Choose a date between {0} and {1}.',
    delivery_nos_date_modal_text_dates: 'Choose an optional final delivery date between {0} and {1}.',
    delivery_nos_date_modal_text: 'Here you can limit the delivery date period for NOS.',
    start_delivery_date: 'Select start date',
    end_delivery_date: 'End delivery date',
    nos_date_error_title: 'Error selecting the date!',
    nos_date_error_text: 'The start date must be earlier than the end date.',
    select_date: 'Select date',
    reset: 'Reset quantities',
    reset_title: 'Do you really want to reset all quantities for the selected delivery date?',
    reset_text: 'This can not be undone.',
    redirect: 'redirect',
    order_redirect_title: 'Product expired',
    order_redirect_text: 'Unfortunately the product has already expired, would you like to be redirected to the order?',
    delivery_dates: 'Delivery dates',
    rounded_flash: 'The entered value was rounded up to the next possible packaging unit',
    table_header: 'Branch/Color/Size',
    pieces: 'PC',
    order_deadline: 'Order Deadline {0}',
    available_colors: 'Available colors for selected date:',
    delivery_date_modal_delete_text: 'The following delivery date will be deleted',
    edit_products_not_available:
        'Some selected products will not be available for this delivery date.',
    cw: 'CW',
    no_date_title: 'No available delivery date',
    no_date_text: 'New delivery date can not be added since there is no free date available.',
    tooltip_deadline: 'Deadline for delivery date: {0}',
    tooltip_color_available: 'Delivery period: {0} - {1}',
    tooltip_min_order_abstract: 'Min. order amount: {0} PC per model',
    tooltip_min_order_color: 'Min. order amount {0} PC per color',
    tooltip_min_order_concrete: 'Min. order amount {0} PC per product',
    tooltip_min_order: 'Min. order amount {0} pc per color',
    tooltip_pu: 'Packaging unit: {0}',
    deadline: 'Deadline',
    amount_sku: 'Amount',
    skus: 'SKUs',
    vk_sum: 'UPE-Sum',
    ek_sum: 'EK-Sum',
    excluding_discount: 'excl. Discounts',
    post_error: 'An error has occurred',
    post_error_text: 'Error updating matrix on server',
    post_error_general_failure_text: 'Oops, an error occurred.',
    min_quantity_color_warning: 'This product has a minimum purchase Quantity of {0} per color',
    min_quantity_color_warning_branch: 'This product has a minimum purchase Quantity of {0} per color and branch',
    min_quantity_abstract_warning: 'This product has a minimum purchase Quantity of {0} per model and branch',
    min_quantity_matrix_close_warning: 'This product has a minimum order quantity. Your order will be automatically saved once the minimum order quantity is reached. Please add additional quantities.',
    min_quantity_matrix_close_warning_head: 'Error in quantity selection.',
    matrix_nos_loading_issue_headline: 'Error loading NOS quantities.',
    matrix_nos_loading_issue: 'Die NOS Mengen konnten derzeit nicht geladen werden, bitte versuchen sie es später erneut.',
    min_of: 'Min. {0}/{1}',
    delivery_date_created: 'Delivery date created',
    delivery_date_created_text: 'You can now enter quantities for the delivery date.',
    delivery_date_updated: 'Changes saved',
    delivery_date_updated_text: 'The changes to the delivery date have been successfully applied.',
    delivery_date_not_updated: 'Delivery date temporarily created',
    delivery_date_not_updated_text: 'Please ensure that quantities are stored for all delivery dates.',
    delivery_date_deleted: 'Delivery date removed',
    delivery_date_deleted_text: 'The delivery date including the quantities were successfully deleted.',
    matrix_order_fields_header: 'Additional order fields and notes',
    matrix_order_fields_required_message: 'This field is required',
    select_all: 'Select all',
    copy: 'Copy',
    cut: 'Cut',
    paste: 'Paste',
    modifier_key_win: 'Ctrl',
    modifier_key_osx: '⌘',
    control: 'Control',
    quantity: 'Quantity',
    view: 'View',
    reset_matrix: 'Reset',
    matrix_edited: 'Edited',
    branch_cluster: 'Branch Cluster',
    module_cluster: 'Module Cluster',
    branch_cluster_toast_headline: 'No Branch Cluster available',
    branch_cluster_toast: 'There are currently no branch classes stored for the selected model',
    reset_matrix_success: 'Quantities successfully reset',
    reset_matrix_success_text: 'All quantities were successfully removed',
    cart_items_missing: 'The preview mode has been activated! An error occurred while synchronizing the data with the shopping cart.',
    header_field_values_load_error: 'The values for additional header fields cannot be loaded.',
    header_field_values_load_error_header: 'Loading error!',
    header_field_values_update_error: 'The values for additional header fields cannot be saved.',
    header_field_values_update_error_header: 'Saving error!',
    saving: 'saving...',
    quantities_saved: 'Quantities saved',
    quantities_save_err: 'Quantities could not be saved!',
    total_deliveries: 'Total Deliveries ',
    total_sum: 'Total Sum',
    delivery_date: 'Delivery Date',
    total_pieces: 'pcs',
    trading_margin: 'HASP',
    upe: 'UPE',
    ek: 'EK',
    ex_condition: '[ex. Con.]',
    matrix_locked_channeling: 'This item falls under the selective distribution agreement of the relevant supplier. Please get in touch with your sales contact.',
    matrix_locked_supplier: 'As a supplier, you are not allowed to buy any pre-order products.',
    matrix_locked_delivery: 'This product variant is not available for the selected delivery date.',
    matrix_locked_product_not_available: 'This product variant is not available and therefore cannot be ordered.',
    matrix_can_not_order: 'Only members with order permission, can select product.',
    cart: 'Cart',
    capture: 'Capture',
    matrix: 'Matrix',
    additional_fields_invalid_headline: 'Fill mandatory additional order fields',
    additional_fields_invalid_messages: 'Attention, process can only be completed when mandatory additional order fields have been filled in.',
    additional_fields_invalid_label: 'Please fill in 1 additional order field | Please fill in {n} additional order fields',
    fill_now: 'Fill now',
    matrix_locked_cart_closed: 'This cart has already been released and can no longer be edited.',
    matrix_locked_nos: 'This Product is not traded NOS or Saison product.',
    matrix_locked_not_writable: 'The NOS quantities for this variant have already been maintained in your merchandise management and therefore cannot be edited.',
    nos_maintain: 'NOS Maintain',
    maintain: 'Maintain:',
    minimal_quantity: 'Minimalquantity[MQ]',
    default_stock: 'Default Stock[DS]',
    default_stock_short: 'DS:',
    cluster_loading: 'Loading Branch Cluster...',
    minimal_quantity_short: 'MM:',
    nos_2_0_tooltip: "The NOS tab offers you the opportunity to maintain minimum stocks and standard stocks for the pre-order, which, however, only become effective for the subsequent order (trigger an order 6 months later).",
    module_minQuantity_headline: 'Below the minimum quantity',
    module_minQuantity_message: 'The minimum quantity must not be undercut. Please increase quantities first in order to be able to reduce quantities elsewhere for the color.',
    module_minQuantity_article_headline: 'Mix & Match',
    module_minQuantity_article_message: 'With Mix & Match, the allocated quantities per size are binding. However, the quantities can be increased.',
    sb_min_quantity_error_head: 'Error inserting product quantity!',
    sb_min_quantity_error_text: 'The value of the standard stock cannot be less than the value of the minimum quantity.',
    remember_delivery_date: 'Remember delivery date?',
    remember_delivery_date_tooltip_text: 'The delivery date is noted for the next product from this brand and supplier for which no quantities have yet been written. Appointments that have already been created will not be affected.',
    delivery_date_save_text: 'Save appointment for {0} products from supplier {1}',
    edited_toggle_title: 'You need to add quantities to set the processing status.',
    store_net_prices: 'Store net price',
    net_prices_button_title: 'Edit net prices',
    net_prices_info_text: 'Changes are not saved when you exit the window or switch tabs.',
    net_prices_save: 'Save net price',
    net_prices_delete: 'Delete net price',
    net_prices_modal_tab_model: 'For model',
    net_prices_modal_tab_variant: 'Per color',
    net_prices_modal_tab_article: 'Per size',
    net_price_model_saved_text: 'The net price has been successfully saved.',
    net_price_model_deleted_text: 'The net price has been successfully deleted.',
    net_prices_warning: 'Attention, you will overwrite the previously maintained net price.',
    update_delivery_date_gtin_not_available_headline: 'GTINs not available for new Date',
    update_delivery_date_gtin_not_available_text: 'Unfortunately, the following GTINs are not available for the selected delivery date: {0}',
    branch_classes_not_compatible: 'Branch classes not compatible!',
    branch_classes_not_compatible_text: 'Unfortunately the newly selected store class is not compatible with the maintained quantities, would you like to reset the quantities?',
    branch_classes_not_compatible_text_modules: 'Please check your branch class distribution and the stored order quantities, these are currently not compatible with the specified initial quantities of the module',
    reset_cluster: 'Reset',
    date_warning_tooltip_header: 'Latest release on {0}',
    date_warning_tooltip_text1: 'The next deadline for this shopping cart is',
    date_warning_tooltip_text2: ' Please release your shopping cart by this date to successfully order the goods.',
    date_tooltip_time: ', 11:59 p.m.',
    date_error_tooltip_header: 'Deadline expired',
    date_error_tooltip_text1: 'The deadline expired on',
    date_error_tooltip_text2: ' You can still release the shopping cart, but please note the supplier\'s terms and conditions.',
    deadline_expired: 'Period for the order expired',
    deadline_expired_text: 'The order period is already in the past. Once the product is deleted from the shopping cart, it can no longer be added.',
    actualize_prices: 'Actualize prices',
    available_quantities_exceeded_headline: 'Quantity Availability Exceeded!',
    available_quantities_exceeded_text: 'Your desired order quantity is currently unavailable. Your order quantity has been reduced to the maximum possible amount.',
    available_quantities_tooltip: 'Available quantity: {0}',
    available_quantities_tooltip_date: 'Available quantity: {0}. More quantities are expected to be available from {1}.',
    contain: 'Contain: ',
    not_contain: 'Not included: ',
    further_branches_with_modules: 'Weitere Filialen mit Moduleanwahl',
    search_branchcluster: 'Search branch-cluster...',
};
