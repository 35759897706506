export default {
    mouse: 'Maus',
    keyboard: 'Tastatur',
    branch_cluster_update_title: 'Update erfolgreich',
    branch_cluster_update_text_changed: 'Filialklassenverteilung wurde erfolgreich geändert.',
    branch_cluster_update_text_disabled: 'Filialklassenverteilung wurde entfernt.',
    delivery_date_modal_title: 'Liefertermin',
    delivery_date_nos_modal_title: 'Nachordertermin',
    delivery_date_modal_text: 'Wählen Sie einen Termin zwischen dem {0} und {1}.',
    delivery_nos_date_modal_text_dates: 'Wählen Sie einen optionalen End-Liefertermin zwischen dem {0} und {1}.',
    delivery_nos_date_modal_text: 'Hier können Sie den Lieferterminzeitraum für NOS einschränken.',
    start_delivery_date: 'Starttermin wählen',
    end_delivery_date: 'End-Liefertermin',
    nos_date_error_title: 'Fehler bei der Terminauswahl!',
    nos_date_error_text: 'Starttermin muss früher liegen als Endtermin.',
    select_date: 'Datum auswählen',
    reset: 'Mengen zurücksetzen',
    reset_title: 'Möchten Sie wirklich alle Mengen für den ausgewählten Liefertermin zurücksetzen?',
    reset_text: 'Dies kann nicht rückgängig gemacht werden.',
    redirect: 'Weiterleiten',
    order_redirect_title: 'Produkt abgelaufen',
    order_redirect_text: 'Leider ist das Produkt bereits abgelaufen, möchten Sie zu der Bestellung weitergeleitet werden?',
    delivery_dates: 'Liefertermine',
    rounded_flash: 'Der Wert wurde gerundet',
    rounded_flash_text: 'Der eingegebene Wert wurde auf die nächstmögliche Verpackungseinheit gerundet',
    table_header: 'Filiale/Farbe/Grösse',
    pieces: 'ST',
    order_deadline: 'Order Deadline {0}',
    available_colors: 'Verfügbare Farben zum gewählten Liefertermin:',
    delivery_date_modal_delete_text: 'Folgender Termin wird aus der Mengenerfassung gelöscht',
    edit_products_not_available:
        'Einige bereits ausgewählte Produkte sind zu diesem Liefertermin nicht verfügbar und werden entfernt.',
    cw: 'KW',
    no_date_title: 'Kein freies Datum',
    no_date_text:
        'Neues Lieferdatum kann nicht angelegt werden, da kein freies Datum verfügbar ist.',
    tooltip_deadline: 'Deadline für Liefertermin: {0}',
    tooltip_color_available: 'Lieferzeitraum: {0} - {1}',
    tooltip_min_order_abstract: 'Min. Bestellmenge: {0} Stck. je Modell',
    tooltip_min_order_color: 'Min. Bestellmenge {0} Stck. je Farbe',
    tooltip_min_order_concrete: 'Min. Bestellmenge {0} Stck. je Produkt',
    tooltip_pu: 'Verpackungseinheit: {0}',
    deadline: 'Deadline',
    amount_sku: 'Menge',
    skus: 'SKUs',
    vk_sum: 'UPE-Summe',
    ek_sum: 'EK-Summe',
    excluding_discount: 'exkl. Rabatte',
    post_error: 'Ein Fehler ist aufgetreten',
    post_error_text: 'Fehler beim Senden der Daten an Server',
    post_error_general_failure_text: 'Ups, ein Fehler ist aufgetreten.',
    min_quantity_color_warning: 'Dieses Produkt hat eine Mindestbestellmenge von {0} pro Farbe',
    min_quantity_color_warning_branch: 'Dieses Produkt hat eine Mindestbestellmenge von {0} pro Farbe je Filiale',
    min_quantity_abstract_warning: 'Dieses Produkt hat eine Mindestbestellmenge von {0} pro Modell je Filiale',
    min_quantity_matrix_close_warning: 'Für dieses Produkt gilt eine Mindestbestellmenge. Ihre Bestellung wird automatisch gespeichert, sobald die Mindestbestellmenge erreicht ist. Bitte fügen Sie weitere Mengen hinzu.',
    min_quantity_matrix_close_warning_head: 'Fehler bei der Auswahl von Mengen.',
    matrix_nos_loading_issue_headline: 'Fehler beim Laden der NOS Mengen.',
    matrix_nos_loading_issue: 'Die NOS Mengen können derzeit nicht geladen werden, bitte versuchen sie es später erneut.',
    min_of: 'Min. {0}/{1}',
    delivery_date_created: 'Liefertermin angelegt',
    delivery_date_created_text: 'Sie können für den Termin nun Mengen erfassen.',
    delivery_date_updated: 'Änderungen gespeichert',
    delivery_date_not_updated: 'Liefertermin enthält noch keine Mengen',
    delivery_date_not_updated_text: 'Bitte stellen Sie sicher, dass für den gewählten Liefertermin auch Mengen gespeichert werden.',
    delivery_date_updated_text: 'Die Änderungen des Termins wurden erfolgreich übernommen.',
    delivery_date_deleted: 'Liefertermin gelöscht',
    delivery_date_deleted_text: 'Der Termin inklusive der Mengen wurden erfolgreich gelöscht',
    matrix_order_fields_header: 'Notizen und Zusatzkopffelder',
    matrix_order_fields_required_message: 'Dieses Feld ist erforderlich',
    select_all: 'Alles auswählen',
    copy: 'Kopieren',
    cut: 'Ausschneiden',
    paste: 'Einfügen',
    modifier_key_win: 'Strg',
    modifier_key_osx: '⌘',
    control: 'Bedienung',
    quantity: 'Menge',
    view: 'Ansicht',
    reset_matrix: 'Zurücksetzen',
    matrix_edited: 'Bearbeitet',
    reset_matrix_success: 'Mengen erfolgreich zurückgesetzt',
    reset_matrix_success_text: 'Alle Mengen wurden entfernt',
    cart_items_missing: 'Der Vorschaumodus wurde aktiviert! Ein Fehler bei der Synchronisierung der Daten mit dem Warenkorb ist aufgetreten.',
    header_field_values_load_error: 'Die Werte für die Zusatzkopffelder konnten nicht geladen werden.',
    header_field_values_load_error_header: 'Fehler beim laden!',
    header_field_values_update_error: 'Die Werte für die Zusatzkopffelder konnten nicht gespeichert werden.',
    header_field_values_update_error_header: 'Fehler beim speichern!',
    total_deliveries: 'Summe für LT ',
    total_sum: 'Gesamtsumme',
    saving: 'wird gespeichert...',
    quantities_saved: 'Mengen gespeichert',
    quantities_save_err: 'Mengen konnten nicht gespeichert werden!',
    delivery_date: 'Liefertermin',
    total_pieces: 'Stk',
    trading_margin: '%AB',
    upe: 'UPE',
    ek: 'EK',
    ex_condition: '[exkl. Kond.]',
    matrix_locked_channeling: 'Dieser Artikel fällt unter die selektiven Vertriebsvereinbarung des entsprechenden Lieferanten. Bitte kontaktieren Sie Ihren Vertriebs-Ansprechpartner.',
    matrix_locked_supplier: 'Als Lieferant dürfen Sie keine Produkte in der Vororder einkaufen.',
    matrix_locked_delivery: 'Diese Produktvariante ist für den ausgewählten Liefertermin nicht verfügbar.',
    matrix_locked_product_not_available: 'Diese Produktvariante ist nicht verfügbar und somit nicht bestellbar.',
    matrix_can_not_order: 'Nur Mitglieder mit Bestellerlaubnis können das Produkt auswählen.',
    matrix_locked_fallback: 'Diese Produktvariante ist derzeit nicht verfügbar.',
    matrix_locked_future: 'Diese Produktvariante ist erst ab dem {date} bestellbar.',
    cart: 'Warenkorb',
    capture: 'Erfassung',
    branch_cluster: 'Filialklassen',
    module_cluster: 'Modulklassen',
    branch_cluster_toast_headline: 'Keine Filialklassen vorhanden',
    branch_cluster_toast: 'Derzeit sind keine Filialklassen für das gewählte Modell hinterlegt.',
    matrix: 'Matrix',
    additional_fields_invalid_headline: 'Pflicht-Zusatzkopffelder befüllen',
    additional_fields_invalid_messages: 'Achtung, Prozess kann erst abgeschlossen werden wenn Pflichtzusatzkopffelder ausgefüllt wurden.',
    additional_fields_invalid_label: 'Bitte noch 1 Zusatzkopffeld ausfüllen | Bitte noch {n} Zusatzkopffelder ausfüllen',
    fill_now: 'Jetzt ausfüllen',
    matrix_locked_cart_closed: 'Dieser Warenkorb wurde bereits freigegeben und kann daher nicht mehr bearbeitet werden.',
    matrix_locked_nos: 'Bei diesem Produkt handelt es sich um kein NOS oder Saison Produkt.',
    matrix_locked_not_writable: 'Die NOS-Mengen für diese Variante wurde bereits in Ihrer Warenwirtschaft gepflegt und kann deshalb nicht editiert werden.',
    nos_maintain: 'NOS Pflege',
    maintain: 'Pflege:',
    minimal_quantity: 'Minimalmenge[MM]',
    default_stock: 'Standardbestand[SB]',
    default_stock_short: 'SB:',
    cluster_loading: 'Filialklassen werden geladen...',
    minimal_quantity_short: 'MM:',
    nos_2_0_tooltip: "Der NOS-Tab bietet Ihnen die Möglichkeit, bereits zur Vororder Minimalbestände und Standardbestände zu pflegen, welche allerdings erst zur Nachorder wirksam werden (6 Monate später einen Auftrag auslösen).",
    module_minQuantity_headline: 'Mindestmenge unterschritten',
    module_minQuantity_message: 'Mindestmenge darf nicht unterschritten werden. Bitte zuerst Mengen erhöhen, um an einer anderen Stelle der Farbe Mengen reduzieren zu können.',
    module_minQuantity_article_headline: 'Mix & Match',
    module_minQuantity_article_message: 'Bei Mix & Match sind die zugeteilten Mengen pro Größe verbindlich. Die Mengen können jedoch erhöht werden.',
    sb_min_quantity_warning_head: 'Fehler beim Einfügen der Produktmenge!',
    sb_min_quantity_warning_text: 'Der Wert des Standardbestands darf nicht kleiner sein als der Wert der Minimalmenge.',
    remember_delivery_date: 'Liefertermin merken?',
    remember_delivery_date_tooltip_text: 'Der Liefertermin wird für das nächste Produkt von dieser Marke und von diesem Lieferanten gemerkt, bei dem noch keine Mengen geschrieben wurden. Bereits angelegte Termine werden nicht beeinflusst.',
    delivery_date_save_text: 'Termin für {0}-Produkte von Lieferant {1} merken',
    edited_toggle_title: 'Sie müssen Mengen hinzufügen um Bearbeitet-Status zu setzen.',
    store_net_prices: 'Nettopreise hinterlegen',
    net_prices_button_title: 'Nettopreise bearbeiten',
    net_prices_info_text: 'Änderungen werden beim Verlassen des Fensters oder Wechsel der Tabs nicht gespeichert.',
    net_prices_save: 'Nettopreis speichern',
    net_prices_delete: 'Nettopreis löschen',
    net_prices_modal_tab_model: 'Für Modell',
    net_prices_modal_tab_variant: 'Je Farbe',
    net_prices_modal_tab_article: 'Je Größe',
    net_price_model_saved_text: 'Der Nettopreis wurde erfolgreich gespeichert.',
    net_price_model_deleted_text: 'Der Nettopreis wurde erfolgreich gelöscht.',
    net_prices_warning: 'Achtung, Sie überschreiben den bisher gepflegten Nettopreis.',
    update_delivery_date_gtin_not_available_headline: 'GTINs für neuen LT nicht verfügbar',
    update_delivery_date_gtin_not_available_text: 'Leider sind die folgenden GTINs für den ausgewählten Liefertermin nicht verfügbar: {0}',
    branch_classes_not_compatible: 'Filialklassen nicht kompatibel!',
    branch_classes_not_compatible_text: 'Leider ist die neu ausgewählte Filialklasse nicht mit den gepflegten Mengen kompatibel, möchten Sie die Mengen zurücksetzen?',
    branch_classes_not_compatible_text_modules: 'Bitte überprüfen Sie Ihre Filialklassenverteilung und die hinterlegten Bestellmengen. Leider sind diese nicht mit den Initalmengen des Modules kompatibel.',
    reset_cluster: 'Zurücksetzen',
    date_warning_tooltip_header: 'Späteste Freigabe am {0}',
    date_warning_tooltip_text1: 'Die nächstgelegene Deadline dieses Warenkorbes ist der',
    date_warning_tooltip_text2: ' Geben Sie den Warenkorb bis zu diesem Termin frei, um die Ware erfolgreich zu ordern.',
    date_tooltip_time: ', 23:59 Uhr.',
    date_error_tooltip_header: 'Deadline abgelaufen',
    date_error_tooltip_text1: 'Die Deadline ist am',
    date_error_tooltip_text2: 'abgelaufen. Sie können den Warenkorb dennoch freigeben, beachten Sie jedoch die Bestimmungen des Lieferanten.',
    deadline_expired: 'Zeitraum für die Bestellung abgelaufen',
    deadline_expired_text: 'Der Bestellzeitraum liegt bereits in der Vergangenheit. Sobald das Produkt aus dem Warenkorb gelöscht wird, kann es nicht mehr hinzugefügt werden.',
    actualize_prices: 'Preise aktualisieren',
    available_quantities_exceeded_headline: 'Bestellmenge nicht verfügbar!',
    available_quantities_exceeded_text: 'Ihre gewünschte Bestellmenge is derzeit nicht verfügbar, Ihre Bestellmenge wurde auf die höchstmögliche Menge reduziert.',
    available_quantities_tooltip: 'Verfügbare Menge: {0}',
    available_quantities_tooltip_date: 'Verfügbare Menge: {0}. Mehr Mengen sind voraussichtlich ab {1} verfügbar.',
    contain: 'Enthalten: ',
    not_contain: 'Nicht enthalten: ',
    further_branches_with_modules: 'Weitere Filialen mit Moduleanwahl',
    search_branchcluster: 'Filialklasse suchen...',
};
