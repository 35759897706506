 
export enum ResponseStatusTypes {
    // eslint-disable-next-line
    NOT_FOUND = 404,
    // eslint-disable-next-line
    SUCCESS = 200,
    // eslint-disable-next-line
    SERVER_ERROR = 500,
    // eslint-disable-next-line
    FORBIDDEN = 403,
    // eslint-disable-next-line
    UNAUTHORIZED = 401
}
