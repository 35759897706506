 

export default {
    message_home_page: 'You are logged out. Please go to the {platform_name} platform via the ',
    message_home_page_portal: 'INTERSPORT Portal',
    cookie_setting: 'Cookie-Settings',
    intersport_portal_link:
        'https://portal.intersport.de/irj/portal?NavigationTarget=navurl://9a9106b641f0870e3d4eae5b87474636&NavMode=0',
    intersport_germany_eg: '©{currentYear} INTERSPORT Germany eG',
};
