

export default {
    model_number: 'M-Nr.',
    model_number_predecessor: 'VG: ',
    model_number_predecessor_title: 'Vorgänger Modell',
    model_number_successor: 'NF: ',
    model_number_successor_title: 'Nachfolger Modell',
    product_groups: "WGR:",
    ek_price: 'EK',
    kern_price: 'KERN',
    vk_price: 'VK',
    upe_price: 'UPE',
    upa_price: 'UPA',
    m_number: 'M-Nr.',
    from: 'ab',
    color_number: 'FarbNr.',
    matrix: 'Mengenerfassung',
    single_purchase: 'Einzelerfassung',
    product_info: 'Produktinfo',
    product_infos: 'Produktinformationen',
    volume_discount: 'Mengenstaffel',
    item_list: 'Stückliste',
    nos_tooltip: 'NOS-Artikel (Never Out of Stock) werden so bevorratet, dass Sie über die gesamte Artikellaufzeit jeden Tag verfügbar sind.',
    ps_tooltip: 'Preissprünge treten meist bei Produkten mit Material- bzw. Größenvariationen auf.',
    kern_tooltip: 'KERN Artikel sind Produkte ausschließlich von Industriemarken, die an Kernteilnehmer direkt vom jeweiligen Markenhersteller zu Kernkonditionen geliefert werden.',
    saison_tooltip: 'Ein SAISON-Artikel unterliegt häufig einer stark schwankenden saisonalen Nachfrage. Daher kann es auch mal vorkommen, dass ein SAISON-Artikel vor Ende der Hauptsaison ausverkauft ist.',
    ms_tooltip: 'Mengenstaffel: dieses Produkt wird beim Kauf einer größeren Menge günstiger. Preisdetails finden Sie bei der Staffelangabe in der Matrix oder auf der Produktdetailseite.',
    fk_tooltip: 'FOKUS KERN Artikel sind Artikel mit Bestkonditionen der wichtigsten Industrie und Exklusivmarken, die ausschließlich aus dem INTERSPORT Lager an Sie geliefert werden.',
    variants: 'Varianten',
    lot_table_header: 'Enthaltende Farben & Größen',
    netto_tooltip: 'Bei einem NETTO-Artikel gibt es keine weiteren Rabatte.',
    notes_overview: 'Notizen Übersicht',
    no_notes_available: 'Keine Notizen verfügbar',
    add_notes_text: 'Fügen Sie Notizen zu Modellen hinzu, um diese hier zu sehen.',
    create_note: 'Notiz anlegen für',
    edit_note: 'Notiz bearbeiten für',
    characters: 'Zeichen',
    add_note: 'Notiz hinzufügen...',
    created: "Erstellt",
    note_saved: "Notiz gespeichert!",
    note_not_saved: "Notiz nicht gespeichert!",
    note_deleted: "Notiz wurde gelöscht!",
    note_saved_text: 'Notiz wurde erfolgreich gespeichert!',
    note_deleted_text: 'Notiz wurde erfolgreich gelöscht!',
    note_not_loaded: 'Notiz konnte nicht geladen werden!',
    saving: 'Wird gespeichert',
    note_is_saved: 'Notiz gespeichert',
    last_modified: 'Zuletzt gespeichert',
    delete_note: 'Notiz löschen',
    delete_note_text: 'Möchten Sie diese Notiz wirklich löschen?',
    created_edited: 'Erstellt/Bearbeitet: ',
    by: 'von',
    notes: 'Notizen',
    add_to_cart: 'In den Warenkorb',
    fedas: 'Fedas',
    add_fedas_title: 'Mitglieder-Fedas hinterlegen',
    search_fedas_placeholder: 'Fedas-Nummer oder -Bezeichnung suchen',
    reset_fedas: 'Auf Original-Fedas zurücksetzen',
    save_fedas: 'Fedas speichern',
    update_fedas_error_headline: 'Fehlgeschlagen',
    update_fedas_error_text: 'Aktualisieren des Fedas fehlgeschlagen.',
    immediately_available: 'Sofort verfügbar',
    low_inventory: 'Niedriger Lagerbestand',
    not_available: 'Nicht verfügbar',
    expected_more_available_from: 'voraussichtlich mehr verfügbar ab',
    available_again: 'ab {0} wieder verfügbar',
};
