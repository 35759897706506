import refreshAccessToken from '~/composables/api/searchDiscover/refreshAccessToken';

export const removeTokensFromCookieRedirectToLogin = () => {
    const { $cookies } = useNuxtApp();
    const config = useRuntimeConfig();

    $cookies.remove(CookieNameTypes.TOKEN_KEY);
    if (config.public.intersportPortalUrl && config.public.intersportPortalUrl !== '') {
        location.replace(config.public.intersportPortalUrl);
    } else {
        $cookies.remove(CookieNameTypes.REFRESH_KEY);
        location.replace('/');
    }
};

export const getAccessWithRefreshToken = async() => {
    const { $cookies } = useNuxtApp();
    const apiStore = useApiStore();
    const expireTime = 604800;
    $cookies.remove(CookieNameTypes.TOKEN_KEY);

    if (apiStore.refreshTokenLoading) {
        await new Promise(() => {
            // will wait until another refresh-token request is finished
        });

        return;
    }

    apiStore.refreshTokenLoading = true;
    const newTokenResponse = await refreshAccessToken();
    apiStore.refreshTokenLoading = false;

    if ('data' in newTokenResponse && newTokenResponse.data) {
        const newAccessTokenBearer = `Bearer ${newTokenResponse.data.attributes.accessToken}`;
        const newRefreshTokenBearer = newTokenResponse.data.attributes.refreshToken;
        const group = encryptString(newTokenResponse.data.attributes.group);

        $cookies.set(CookieNameTypes.TOKEN_KEY, newAccessTokenBearer, newTokenResponse.data.attributes.expiresIn);
        $cookies.set(CookieNameTypes.REFRESH_KEY, newRefreshTokenBearer, expireTime);
        $cookies.set(CookieNameTypes.GROUP_KEY, group, expireTime);
        $cookies.set(CookieNameTypes.REFRESH_KEY_COUNTER, 0);
        location.reload();
    } else {
        removeTokensFromCookieRedirectToLogin();
    }
};
