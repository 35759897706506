import { createI18n } from 'vue-i18n';
import de from '@/lang/de/index';
import en from '@/lang/en/index';

export default defineNuxtPlugin(nuxtApp => {
    const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        allowComposition: true,
        locale: 'de',
        messages: {
            de: de,
            en: en,
        },
    });

    nuxtApp.vueApp.use(i18n);
    nuxtApp.provide('t', i18n.global.t);
    nuxtApp.provide('te', i18n.global.te);
});
