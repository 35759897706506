 
export enum NuxtLinkNameTypes {
    // eslint-disable-next-line no-unused-vars
    HOME = '/',
     
    LOGIN_POST = '/login-post',
    ACCOUNT_PAGE = '/my-account',
    BRANCH_CLUSTERS = '/my-account/branch-clusters',
    CARTS_PAGE = '/my-account/carts',
    CART_PAGE = '/cart',
    // eslint-disable-next-line no-unused-vars
    CATEGORY = '/c',
    // eslint-disable-next-line no-unused-vars
    PRODUCT = '/p',
    CATALOG_SEARCH = '/c/catalog-search',
    ORDER_DETAILS_PAGE = '/my-account/order-details',
    ORDER_HISTORY_PAGE = '/my-account/order-history',
    ADDRESSES_PAGE = '/my-account/addresses',
    CONDITION_SHEETS_PAGE = '/my-account/condition-sheets',
    WORKBOOK_PAGE = '/workbook',
    NOTES_PAGE = '/product-notes',
    // eslint-disable-next-line no-unused-vars
    SMS = '/sms',
}
