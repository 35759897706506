const date = new Date();

export default {
    footer_portal_text: 'Besuchen Sie auch unsere weiteren Services rund um die Themen Waren und Produkte:',
    intersport_germany: `© ${date.getFullYear()} INTERSPORT Deutschland eG`,
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    conditions: 'AGB',
    delivery_shipping_regulations: 'Liefer- & Versand-Vorschriften',
    cookie_settings: 'Cookie-Einstellungen',
    fair: 'Messe',
    no_shop: 'Nachorder Shop',
};
