export default {
    narrow_down_your_selection: 'Narrow down your product selection',
    reset_filter: 'Reset filter',
    apply_filter: 'Confirm filters',
    text: 'Text',
    product_filter: 'Filter products',
    select_criteria_text: 'Select the criteria you want to filter by.',
    filter: 'Filter',
    manage_filters: 'Manage filters',
    brand_asc: 'Brand ascending',
    brand_desc: 'Brand descending',
    name_asc: 'Alphabetically ascending ',
    name_desc: 'Alphabetically descending',
    price_asc: 'Price ascending',
    price_desc: 'Price descending',
    note_new: 'New notes first',
    note_old: 'Old notes first',
    storage_ranges: 'Storage ranges',
    conditions: 'Conditions',
    choose_all: 'Choose all',
};
