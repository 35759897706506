 

export default {
    message_home_page:
        'Sie sind ausgeloggt. Zur {plattform_name}-Plattform gehen Sie bitte über das ',
    message_home_page_portal: 'INTERSPORT Portal',
    cookie_setting: 'Cookie-Einstellungen',
    intersport_portal_link:
        'https://portal.intersport.de/irj/portal?NavigationTarget=navurl://9a9106b641f0870e3d4eae5b87474636&NavMode=0',
    intersport_germany_eg: '©{currentYear} INTERSPORT Deutschland eG',
};
