const prefix = 'cached_';
const updateInterval = 1800000;

// !!! Change this version number if any cached data layout changes in next deployment, will force clearing of cached data
const version = 1;

interface CachedData<T> {
    data: T | null,
    expireDate: number,
    version: number,
}

/*
 * key: set unique string as identifier
 * duration: set duration to how long you want the data to be valid in ms
 */
export default function useLocalStorageCache<T>(key: string, duration = updateInterval) {
    const isLocalStorageAvailable = () => {
        try {
            if (localStorage && typeof localStorage !== 'undefined' && typeof localStorage.setItem === 'function') {
                return true;
            }
        } catch (e) {
            console.error('localStorage is not accessible:', e);
        }

        return false;
    };

    const save = (newData: CachedData<T>) => {
        if (isLocalStorageAvailable() && newData) {
            localStorage.setItem(prefix + key, JSON.stringify(newData));
        }
    };

    const load = (): CachedData<T> => {
        const rawData = isLocalStorageAvailable() ? localStorage.getItem(prefix + key) as string | null : null;
        const now = new Date().getTime();
        const parsedData = rawData ?
            JSON.parse(rawData) as CachedData<T> :
            {
                data: null,
                expireDate: now + duration,
                version: version,
            };
        if (parsedData.version !== version || now > parsedData.expireDate) {
            parsedData.data = null;
            parsedData.version = version;
            parsedData.expireDate = now + duration;
            save(parsedData);
        }

        return parsedData;
    };

    let data = load();

    const getCachedData = (): T | null => (new Date().getTime() > data.expireDate ? null : data.data);

    const updateCachedData = (newData: T) => {
        data.data = newData;
        data.expireDate = new Date().getTime() + duration;
        save(data);
        data = load();
    };

    const removeCachedData = () => {
        localStorage.removeItem(prefix + key);
        data = load();
    };

    return {
        getCachedData,
        updateCachedData,
        removeCachedData,
    };
}
