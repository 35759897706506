<template>
    <div class="container">
        <div class="grid grid--gap grid--middle">
            <div class="col col--equal">
                <h1 class="error-page__headline">{{ statusCode }}</h1>
                <h2 class="error-page__subheadline" v-html="$sanitizeHtml($t('General.error_page_subheadline'))"/>
                <p class="error-page__text">{{ $t('General.error_page_text') }}</p>

                <div v-if="showButtons" class="grid error-page__buttons">
                    <template v-if="statusCode != ResponseStatusTypes.FORBIDDEN">
                        <div class="col">
                            <AtomButton
                                :text="$t('General.to_homepage')"
                                @click="navigate(!$hasRole(RoleTypes.SUPPLIER) ? NuxtLinkNameTypes.HOME: NuxtLinkNameTypes.ORDER_HISTORY_PAGE)"
                            />
                        </div>
                        <div class="col">
                            <AtomButton type="secondary" :text="$t('General.try_again')" @click="reloadPage()" />
                        </div>
                    </template>
                    <template v-else>
                        <AtomButton :text="$t('General.to_login')" @click="redirectToLogin()" />
                    </template>
                </div>
            </div>
            <div class="col col--equal">
                <img src="/images/error.png" class="error-page__image" alt="error" >
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ResponseStatusTypes } from '~/composables/types/responseStatusTypes';
import { RoleTypes } from '~/composables/types/roleTypes';
import { NuxtLinkNameTypes } from '~/composables/types/nuxtHyperlinkTypes';

const config = useRuntimeConfig();

defineProps<{
    statusCode: number,
    showButtons: boolean,
}>();

const { $hasRole, $sanitizeHtml } = useNuxtApp();
const reloadPage = () => window.location.reload();

const navigate = (link: string) => {
    navigateTo(link);
};

const redirectToLogin = () => {
    if (config.public.intersportPortalUrl) {
        window.location.href = config.public.intersportPortalUrl;
    } else {
        navigateTo('/');
    }
};
</script>

<style lang="scss" scoped>
.error-page {
    &__headline {
        font-size: rem(68);

        @include helper-color(text-title);
        @include helper-font-weight(medium);
        @include helper-font-line-height(default);
    }

    &__subheadline {
        @include helper-color(text-title);
        @include helper-font-weight(medium);
        @include helper-font-line-height(default);
        @include helper-font-size(1);
    }

    &__text {
        @include helper-color(text-title);
        @include helper-font-weight(regular);
        @include helper-font-line-height(default);
        @include helper-font-size(4);
    }

    &__buttons {
        margin-top: sp(xl);
        gap: sp(s);
    }

    &__image {
        border-radius: 1.25rem;
        pointer-events: none;
    }
}
</style>
