interface StaticPage {
    slug: string,
    content: string | null,
}

interface IStaticPagesState {
    pages: StaticPage[],
}

interface IStaticPagesActions {
    setPages: (arg0: StaticPage[]) => void,
    getPageBySlug: (arg0: string) => StaticPage | undefined,
}

export default defineStore<string, IStaticPagesState, any, IStaticPagesActions>('staticPages', {
    state: () => ({
        pages: [{
            slug: 'imprint',
            content: null,
        }, {
            slug: 'privacy-policy',
            content: null,
        }, {
            slug: 'faq',
            content: null,
        }, {
            slug: 'contact',
            content: null,
        }],
    }),
    actions: {
        setPages(pages: StaticPage[]) {
            this.pages = pages;
        },
        getPageBySlug(slug: string): StaticPage | undefined {
            return this.pages.find((page: StaticPage) => page.slug === slug);
        }
    },
});
