import type { sdApiFetchTypes } from '~/composables/types/api/searchDiscover';
import type { ccApiFetchTypes } from '~/composables/types/api/cartConditions/api';
import { createAbstractFetch } from '~/composables/api/apiFactories/abstract-factory';

export const fetchOrderDetermination = () => {
    const abstractFetch = createAbstractFetch();
    const config = useRuntimeConfig();

    const fetchSdApi: sdApiFetchTypes = abstractFetch.create({
        baseURL: config.public.sdApiUrl,
    }) as sdApiFetchTypes;

    const fetchCcCartApi: ccApiFetchTypes = abstractFetch.create({
        baseURL: config.public.ccApiCartServiceUrl,
    }) as ccApiFetchTypes;

    const fetchCcNosApi: ccApiFetchTypes = abstractFetch.create({
        baseURL: config.public.ccNosServiceUrl,
    }) as ccApiFetchTypes;

    return { fetchSdApi, fetchCcCartApi, fetchCcNosApi };
};
