import type {
    ApiErrorResponse,
    ApiGenericErrorResponse, ApiSuccessResponse, ErrorResponseObject, ErrorResponseString,
} from '~/composables/types/api/apiResponse';

export default function handleError<ErrorDataType>(response: ApiGenericErrorResponse<ErrorDataType> | any)
    : ApiErrorResponse {
    if (typeof response === 'object') {
        if (response?.status) {
            response = response as ApiGenericErrorResponse<ErrorResponseObject<ErrorDataType>>;

            if (response?._data?.errors) {
                return {
                    state: 'failed',
                    error: response._data?.errors?.[0] ?? JSON.stringify(response),
                    status: response.status
                };
            }
        }

        response = response as ApiGenericErrorResponse<ErrorResponseString<ErrorDataType>>;

        if (response?.error) {
            return { state: 'failed', error: response.error, status: 600 };
        }
    }

    return { state: 'failed', error: JSON.stringify(response), status: 600 };
}

export function handleSuccess<SuccessDataType>(response: SuccessDataType)
    : ApiSuccessResponse<SuccessDataType> {
    return {
        ...response,
        error: false,
        state: 'success'
    };
}
