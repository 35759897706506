 

export default {
    supplier: 'Lieferant',
    deadline: 'Deadline',
    amount: 'MENGE',
    ek_type_price: 'EK',
    exclusive_discounts: 'excl. Rabatte',
    upe_upa: 'UPE / UPA',
    dealer_margin: 'Händlerspanne',
    ek_total: 'EK SUMME',
    upe_total: 'UPE SUMME',
    discount: 'RABATT',
    total_ek: 'GESAMT(EK)',
    taxes: 'ZUSCHLÄGE',
    suggestions: 'HINWEISE',
    supplier_selection: 'Lieferantenauswahl',
    supplier_missing: 'Sie müssen mindestens einen Lieferanten auswählen',
    mbm: 'MBM',
};
