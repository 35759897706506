import General from './general.js';
import NoLogged from './no-logged';
import Cart from './cart.js';
import MyOrderDetails from './myOrderDetails';
import ShoppingList from './shopping_list.js';
import Checkout from './checkout';
import Supplier from './supplier';
import SearchFlyout from './searchFlyout.js';
import Plp from './plp.js';
import BreadCrumbs from './breadCrumbs.js';
import Matrix from './matrix';
import CategoryFilter from './categoryFilter.js';
import Product from './product';
import Pdp from './pdp';
import Footer from './footer';
import Workbook from './workbook';

export default {
    General,
    NoLogged,
    Cart,
    MyOrderDetails,
    ShoppingList,
    Checkout,
    Supplier,
    Plp,
    BreadCrumbs,
    SearchFlyout,
    Matrix,
    CategoryFilter,
    Product,
    Pdp,
    Footer,
    Workbook,
};
