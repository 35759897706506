import { default as _91_46_46_46slug_93aKkEIOW6GeMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46category_id_939eL0KfPskhMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/c/[...category_id].vue?macro=true";
import { default as _91cart_id_93B1CepUyqT1Meta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/cart/[cart_id].vue?macro=true";
import { default as contactQtwx8BgHGoMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/contact.vue?macro=true";
import { default as faqAsFwaNzkogMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/faq.vue?macro=true";
import { default as homeYYhMVM3NVzMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/home.vue?macro=true";
import { default as imprintn5LpUwQ1mtMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/imprint.vue?macro=true";
import { default as indexe8kmUF4EpKMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/index.vue?macro=true";
import { default as login_45postdqshRRWFycMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/login-post.vue?macro=true";
import { default as additional_45header_45fields6NWQ3HdJQ5Meta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue?macro=true";
import { default as addressesteA0QOhA2cMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/addresses.vue?macro=true";
import { default as branch_45clustersXXaw3RyJqAMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/branch-clusters.vue?macro=true";
import { default as cartsbbhBFTrrffMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/carts.vue?macro=true";
import { default as condition_45sheetsnN2paJmEvHMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/condition-sheets.vue?macro=true";
import { default as order_45detailsah31MCjadvMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/order-details.vue?macro=true";
import { default as order_45historyuCY1unM8kKMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/order-history.vue?macro=true";
import { default as preferencesvnuHUoonP3Meta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/preferences.vue?macro=true";
import { default as my_45accountC6Ufa4Qjl8Meta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account.vue?macro=true";
import { default as _91id_93jFnMwhdiCCMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/p/[id].vue?macro=true";
import { default as privacy_45policykLBa376eBsMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93Ge6yShVENvMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/product-notes/[...slug].vue?macro=true";
import { default as smsE83rgKECLTMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/sms.vue?macro=true";
import { default as _91_46_46_46category_id_93rLsQnr89eQMeta } from "/codebuild/output/src3590403424/src/intersport-order-frontend/pages/workbook/[...category_id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93aKkEIOW6GeMeta || {},
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "c-category_id",
    path: "/c/:category_id(.*)*",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/c/[...category_id].vue").then(m => m.default || m)
  },
  {
    name: "cart-cart_id",
    path: "/cart/:cart_id()",
    meta: _91cart_id_93B1CepUyqT1Meta || {},
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/cart/[cart_id].vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login-post",
    path: "/login-post",
    meta: login_45postdqshRRWFycMeta || {},
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/login-post.vue").then(m => m.default || m)
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: my_45accountC6Ufa4Qjl8Meta || {},
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account.vue").then(m => m.default || m),
    children: [
  {
    name: "my-account-additional-header-fields",
    path: "additional-header-fields",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue").then(m => m.default || m)
  },
  {
    name: "my-account-addresses",
    path: "addresses",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/addresses.vue").then(m => m.default || m)
  },
  {
    name: "my-account-branch-clusters",
    path: "branch-clusters",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/branch-clusters.vue").then(m => m.default || m)
  },
  {
    name: "my-account-carts",
    path: "carts",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/carts.vue").then(m => m.default || m)
  },
  {
    name: "my-account-condition-sheets",
    path: "condition-sheets",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/condition-sheets.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-details",
    path: "order-details",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/order-details.vue").then(m => m.default || m)
  },
  {
    name: "my-account-order-history",
    path: "order-history",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/order-history.vue").then(m => m.default || m)
  },
  {
    name: "my-account-preferences",
    path: "preferences",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/my-account/preferences.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "product-notes-slug",
    path: "/product-notes/:slug(.*)*",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/product-notes/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "sms",
    path: "/sms",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/sms.vue").then(m => m.default || m)
  },
  {
    name: "workbook-category_id",
    path: "/workbook/:category_id(.*)*",
    component: () => import("/codebuild/output/src3590403424/src/intersport-order-frontend/pages/workbook/[...category_id].vue").then(m => m.default || m)
  }
]