import type { RefreshTokenParams, RefreshTokenRequestData } from '~/composables/types/api/searchDiscover';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function refreshAccessToken(): PromiseResponseData<RefreshTokenRequestData> {
    const { $cookies } = useNuxtApp();
    const requestCounterLimit = 3;
    const api = '/refresh-tokens';
    const cookieRetryKey = CookieNameTypes.REFRESH_KEY_COUNTER;
    const refreshToken = $cookies.get(CookieNameTypes.REFRESH_KEY);
    const config = useRuntimeConfig();
    const { fetchSdApi } = fetchOrderDetermination();

    const params: RefreshTokenParams = {
        data: {
            attributes: {
                refreshToken: refreshToken
            },
            type: 'refresh-tokens',
        },
    };

    if (parseInt($cookies.get(cookieRetryKey), 10) >= requestCounterLimit) {
        // already to many refresh-token access requests redirect to portal
        $cookies.remove(CookieNameTypes.REFRESH_KEY);
        $cookies.remove(cookieRetryKey);
        if (config.public.intersportPortalUrl && config.public.intersportPortalUrl !== '') {
            location.replace(config.public.intersportPortalUrl);
        } else {
            location.replace('/');
        }
    }

    let requestCounter = $cookies.get(cookieRetryKey) ? parseInt($cookies.get(cookieRetryKey), 10) : 0;
    $cookies.set(cookieRetryKey, ++requestCounter);

    try {
        const response = await fetchSdApi(api, {
            method: 'POST',
            body: params
        }) as any;

        return handleSuccess<RefreshTokenRequestData>(response);
    } catch (error) {
        return handleError(error);
    }
}
