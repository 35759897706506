const date = new Date();

export default {
    footer_portal_text: 'Visit our other services related to goods and products:',
    intersport_germany: `© ${date.getFullYear()} INTERSPORT Germany eG`,
    imprint: 'Imprint',
    privacy: 'Privacy',
    conditions: 'Conditions',
    delivery_shipping_regulations: 'Delivery & Shipping Regulations',
    cookie_settings: 'Cookie Settings',
    fair: 'Messe',
    no_shop: 'Nachorder Shop',
};
