export default {
    relevance: 'Nach Relevanz',
    product_groups: 'Warengruppen',
    name_asc: 'Alphabetisch',
    price_asc: 'Preis aufsteigend',
    price_desc: 'Preis absteigend',
    supplier: 'Lieferant',
    also_at: 'Auch bei',
    description: 'Beschreibung',
    sorting: 'Sortierung',
    item: 'Produktmodelle',
    color_title: 'Farbe: ',
    brand_title: 'Marke: ',
    tile: 'Kachel',
    list: 'Liste',
};
