export default {
    create_shopping_list: 'Merkliste anlegen',
    shopping_lists: 'Merklisten',
    create_new_shopping_list: 'Neue Merkliste anlegen',
    name_of_shopping_list: 'Name der Merkliste / Warenkorb vorausgefüllt',
    shopping_list: 'Merkliste',
    all_shopping_list: 'Alle Merklisten',
    new_shopping_list: 'Neue Liste erstellen',
    add_to_shopping_list: 'Auf die Merkliste',
    confirm_add_article_to_shopping_list_message:
        'Auf welcher Merkliste möchten Sie die Artikel speichern?',
    to_shopping_list: 'Zur Merkliste',
    delete_shopping_list: 'Merkliste löschen',
    shopping_list_details: 'Merklisten-details',
    product_bookmark: 'Produkt Merken',
    shopping_list_name: 'Name Merkliste',
    shopping_list_general_presentation: 'Merklistenübersicht',
};
